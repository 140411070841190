import { webLightTheme } from '@fluentui/react-components';
export const projectTheme = webLightTheme;
const SPACE_UNIT = 1;
export const commonTheme = {


    fontSizes: {
        xs: `${1.0 * SPACE_UNIT}rem`,
        sm: `${1.25 * SPACE_UNIT}rem`,
        md: `${1.75 * SPACE_UNIT}rem`,
    } as const,
    zIndexes: {
        tooltip: 1000,
    } as const,
    opacity: {
        disabled: 0.45,
    } as const,

    spaces: {
        xxxxs: `${0.25 * SPACE_UNIT}rem`,
        xxxxm: `${0.35 * SPACE_UNIT}rem`,
        xxxs: `${0.5 * SPACE_UNIT}rem`,
        xxs: `${0.75 * SPACE_UNIT}rem`,
        xs: `${1.0 * SPACE_UNIT}rem`,
        sm: `${1.25 * SPACE_UNIT}rem`,
        md: `${1.75 * SPACE_UNIT}rem`,
        lg: `${2.25 * SPACE_UNIT}rem`,
        xl: `${2.75 * SPACE_UNIT}rem`,
        xxl: `${3.75 * SPACE_UNIT}rem`,
        xxxl: `${5.0 * SPACE_UNIT}rem`,
        xxxxl: `${7.0 * SPACE_UNIT}rem`,

        sideBarWidth: '250px',
        headerHeight: '50px',
    } as const,
} as const;
