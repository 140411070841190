import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverProps, useId } from '@fluentui/react-components';
import { useStyles } from '../../../../Connectors/styles';
import { DivItemFilterSC, HeaderMonitoringSC } from '../../styles';
import { IParamsOnChangeSelection, TOption } from '../../interfaces';
import { useGeneralStore } from '../../../../../store';
import { useTaskStore } from '../../../../../store/useTaskStore';
import { useGetTenants } from '../../../../../api/useGetTenants';
import { useGetConnectors } from '../../../../../api/useGetConnectors';
import { LocationsSelect } from '../../CommonComponents/LocationsSelect';
import { TenantsFilter } from '../../CommonComponents/TenantsFilter';
import { ConnectorTypesSelect } from '../../CommonComponents/ConnectorTypesSelect';
import initialListSettingsTable from '../settings/listSettingsTable';
import { TableSettingsSelect } from '../../CommonComponents/TableSettingsSelect';
import initialListTaskStatusesFilter from '../settings/listTaskStatusesFilter';
import { useGetConnectorTasks } from '../../../../../api/useGetConnectorTasks';
import { useCreateTableListTasks } from '../../../../../hooks/useCreateTableListTasks';
import filteringTableList from '../../../../../tools/filteringTableList';
import { ITableListTasksItem } from '../../../../../types';
import { DateRangePicker } from '../../../../DateRangePicker/DateRangePicker';
import { DATE_FORMAT } from '../../../../../constants/dateFormat';
import dayjs from 'dayjs';
import { LIMIT_GET_TASKS } from '../../constants';
import { useGetLocalStorage } from '../../../../../hooks/useGetLocalStorage';
import { getYesterdayDate } from '../../../../../tools/getYesterdayDate';
import { DateTime } from 'luxon';
import { queryClient } from '../../../../../index';
import { CONNECTOR_TASKS, CONNECTOR_TASKS_GET, GENERAL, TASKS_STORE, TENANTS } from '../../../../../constants';

export const HeaderMonitoring = () => {
    const [openLocationSelect, setOpenLocationSelect] = React.useState<boolean>(false);
    const [openConnectorsType, setOpenConnectorsType] = React.useState<boolean>(false);
    const [openTableSettingsSelect, setOpenTableSettingsSelect] = React.useState<boolean>(false);
    const [openTableFilterSelect, setOpenTableFilterSelect] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const { getGeneralStore } = useGeneralStore();
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const { allLocations } = getGeneralStore();
    const {
        selectedLocations,
        connectorTypes,
        selectedTypesConnector,
        tenantsFilterValue,
        selectedDateMonitoringTo,
        listSettingsTableMonitoring,
        listTaskStatusesFilter,
        connectors,
        tenants,
        tasks,
        tableListTasks,
        tableListTasksCTG,
        selectedCreatedFrom,
        selectedCreatedTo,
        selectedMonitoringDateFrom,
    } = getTasksStore();
    // const { mutate } = usePostTaskCreate();

    // Вычисляемые переменные
    const label = selectedLocations.map((item) => item.name).join(', ');
    const idsList = selectedLocations.map((item) => item.id);

    const labelTypes = selectedTypesConnector.map((item) => item.connector_type).join(', ');
    const idsListTypes = selectedTypesConnector.map((item) => item.connector_type);

    const optionsLocations: TOption[] = allLocations.map((item) => ({
        id: item.id,
        name: item.name,
    }));

    const optionsConnectorType: TOption[] = connectorTypes.map((item) => ({
        id: item.connector_type,
        name: item.connector_type,
    }));

    const listIdSelectedTableSettings = listSettingsTableMonitoring
        .filter((item) => item.checked)
        .map((item) => item.id);
    const labelAllSelectedSettings = listSettingsTableMonitoring
        .filter((item) => item.checked)
        .map((item) => item.name)
        .join(', ');

    const listIdSelectedTableFilter = listTaskStatusesFilter.filter((item) => item.checked).map((item) => item.id);
    const labelAllSelectedFilter = listTaskStatusesFilter
        .filter((item) => item.checked)
        .map((item) => item.name)
        .join(', ');
    // Хуки для получения данных
    const { refetch } = useGetTenants(
        {
            limit: 1000,
            offset: 0,
            plIds: idsList,
            staleTime: 1000
        },
        !openLocationSelect,
    );
    const { refetch: refetchTasks } = useGetConnectorTasks(
        {
            limit: LIMIT_GET_TASKS,

            offset: 0,
            plIds: idsList,
            dateTo: selectedDateMonitoringTo,
            dateFrom: selectedMonitoringDateFrom,
            createdTo: selectedCreatedTo,
            createdFrom: selectedCreatedFrom,
        },
        !openLocationSelect,
    );

    const { refetch: refetch2 } = useGetConnectors(
        {
            limit: 1000,
            offset: 0,
            plIds: idsList,
            staleTime: 1000
        },
        !openLocationSelect,
    );

    useCreateTableListTasks({
        tenants,
        connectors,
        tasks,
        PL: selectedLocations,
    });

    const localSelectedMonitoringDateFrom = useGetLocalStorage<string>('selectedMonitoringDateFrom');
    const localSelectedDateMonitoringTo = useGetLocalStorage<string>('selectedDateMonitoringTo');
    const localSelectedCreatedFrom = useGetLocalStorage<string>('selectedCreatedFrom');
    const localSelectedCreatedTo = useGetLocalStorage<string>('selectedCreatedTo');
    // Инициализация настроек таблицы
    useEffect(() => {
        if (listSettingsTableMonitoring.length === 0) {
            updateTasksStore({
                listSettingsTableMonitoring: initialListSettingsTable,
            });
        }
        if (listTaskStatusesFilter.length === 0) {
            updateTasksStore({
                listTaskStatusesFilter: initialListTaskStatusesFilter,
            });
        }

        if (
            !localSelectedMonitoringDateFrom &&
            !localSelectedDateMonitoringTo &&
            !localSelectedCreatedFrom &&
            !localSelectedCreatedTo
        ) {
            updateTasksStore({
                selectedMonitoringDateFrom: getYesterdayDate(),
                selectedDateMonitoringTo: DateTime.now().toFormat(DATE_FORMAT),
            });
        }
    }, []);

    useEffect(() => {
        updateTasksStore({
            tableListTasks: filteringTableList<ITableListTasksItem>({
                keys: ['tenantId', 'tenantName', 'connectorId'],
                list: tableListTasksCTG,
                valueFilter: tenantsFilterValue,
            }),
        });
    }, [tenantsFilterValue]);

    useEffect(() => {
        if (selectedDateMonitoringTo || selectedMonitoringDateFrom || selectedCreatedTo || selectedCreatedFrom) {
            queryClient.invalidateQueries({ queryKey: [TASKS_STORE, CONNECTOR_TASKS_GET] });
        }
    }, [selectedDateMonitoringTo, selectedMonitoringDateFrom, selectedCreatedTo, selectedCreatedFrom]);

    // Обновление данных при изменении выбранных локаций

    // Обработчики открытия/закрытия поповеров
    const handleOpenChangeLocations: PopoverProps['onOpenChange'] = (e, data) => {
        setOpenLocationSelect(data.open || false);
    };

    const handleOpenChangeConnectorsTypes: PopoverProps['onOpenChange'] = (e, data) => {
        setOpenConnectorsType(data.open || false);
    };

    const handleOpenChangeTableSettings: PopoverProps['onOpenChange'] = (e, data) => {
        setOpenTableSettingsSelect(data.open || false);
    };

    const handleOpenChangeTableFilter: PopoverProps['onOpenChange'] = (e, data) => {
        setOpenTableFilterSelect(data.open || false);
    };

    // Обработчики изменения дат

    const onChangeFromToDate = (from: string | null, to: string | null) => {
        updateTasksStore({
            selectedDateMonitoringTo: to ? to : from ? from : to,
            selectedMonitoringDateFrom: from ? from : to ? to : from,
            selectedCreatedTo: null,
            selectedCreatedFrom: null,

        });

        clearTaskList()

    };

    const clearTaskList = () => {
        setTimeout(() => {
            updateTasksStore({
                tableListTasks: []
            });
        }, 500)
    }

    const onChangeCreatedFromTo = (from: string | null, to: string | null) => {
        updateTasksStore({
            selectedCreatedTo: to ? to : from ? from : to,
            selectedCreatedFrom: from ? from : to ? to : from,
            selectedDateMonitoringTo: null,
            selectedMonitoringDateFrom: null,
        });
        clearTaskList()

    };

    // Обработчики выбора опций
    const selectOption = (params: IParamsOnChangeSelection) => {
        setTimeout(() => {
            updateTasksStore({
                ...params,
            });
        }, 10)

    };

    const filterTableList = (value: string) => {
        updateTasksStore({
            tenantsFilterValue: value,
        });
    };

    return (
        <HeaderMonitoringSC id={'HeaderMonitoring'}>
            <DivItemFilterSC>
                <LocationsSelect
                    labelG={t('Locations')}
                    label={label}
                    open={openLocationSelect}
                    onOpenChange={handleOpenChangeLocations}
                    onChangeSelection={selectOption}
                    listOptions={optionsLocations}
                    selectedIds={idsList}
                    keyName={'selectedLocations'}
                    selectedLocations={selectedLocations}
                    allLocations={allLocations}
                />
            </DivItemFilterSC>

            <TenantsFilter label={t('Tenants filter')} handleChange={filterTableList} value={tenantsFilterValue} />
            <DivItemFilterSC>
                <ConnectorTypesSelect
                    labelG={t('Connectors type')}
                    label={labelTypes}
                    open={openConnectorsType}
                    onOpenChange={handleOpenChangeConnectorsTypes}
                    onChangeSelection={selectOption}
                    listOptions={optionsConnectorType}
                    selectedIds={idsListTypes}
                    keyName={'selectedTypesConnector'}
                    selectedTypesConnector={selectedTypesConnector}
                    connectorTypes={connectorTypes}
                />
            </DivItemFilterSC>
            <DivItemFilterSC>
                <TableSettingsSelect
                    labelG={t('Task status')}
                    label={labelAllSelectedFilter}
                    open={openTableFilterSelect}
                    onOpenChange={handleOpenChangeTableFilter}
                    onChangeSelection={selectOption}
                    listOptions={listTaskStatusesFilter}
                    selectedIds={listIdSelectedTableFilter}
                    keyName={'listTaskStatusesFilter'}
                />
            </DivItemFilterSC>
            <DivItemFilterSC>
                <TableSettingsSelect
                    labelG={t('Table settings')}
                    label={labelAllSelectedSettings}
                    open={openTableSettingsSelect}
                    onOpenChange={handleOpenChangeTableSettings}
                    onChangeSelection={selectOption}
                    listOptions={listSettingsTableMonitoring}
                    selectedIds={listIdSelectedTableSettings}
                    keyName={'listSettingsTableMonitoring'}
                />
            </DivItemFilterSC>
            <DivItemFilterSC>
                <DateRangePicker
                    formatDate={DATE_FORMAT}
                    valueFrom={selectedMonitoringDateFrom}
                    valueTo={selectedDateMonitoringTo}
                    onChange={onChangeFromToDate}
                    title={t('Select date from / to period')}
                    maxDate={dayjs(new Date())}
                />
            </DivItemFilterSC>
            <DivItemFilterSC>
                <DateRangePicker
                    formatDate={DATE_FORMAT}
                    valueFrom={selectedCreatedFrom}
                    valueTo={selectedCreatedTo}
                    onChange={onChangeCreatedFromTo}
                    title={t('Select created from / to period')}
                    maxDate={dayjs(new Date())}
                />
            </DivItemFilterSC>
        </HeaderMonitoringSC>
    );
};
