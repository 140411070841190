import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IBodyTaskCreator, IConnectorItemListForTable, IParamsCancelTask, IStatuses } from '../../interfaces';
import { useTaskStore } from '../../../../../store/useTaskStore';
import { useGeneralStore } from '../../../../../store';

import { KFACTORHEIGHTTABLE_MONITORING } from '../../../../../constants';
import { FocusTable, TableRange } from '../../../../FocusTable';
import {
    BodyMonitoringTasksRowSC,
    CustomTdSC,
    DivActionCellSC,
    DivWrapperChartAndTitleSC,
    DivWrapperChartsContentSC,
} from '../../styles';
import { columnsOptions } from '../settings/tableColumns';
import { useTableColumn } from '../../../../../hooks/useTableColumn';
import { filteringByConnectorTypes } from '../../tools/filteringConnectorTypes';
import { ITableListTasksItem } from '../../../../../types';
import filteringListByTaskStatuses from '../../tools/filteringListByTaskStatuses';
import { usePostTaskCanceler } from '../../../../../api/usePostTaskCanceler';
import { usePostTaskCreate } from '../../../../../api/usePostTaskCreate';
import { ChartBox } from './ChartBox';
import { getPercentage } from '../../tools/getPercentage';
import { getChunkArrays } from '../../tools/getChunkArrays';
import { CHUNK_SIZE } from '../../constants';
import { createBody } from '../../tools/createBody';
import { Button, Tooltip } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { ControlButtons } from './ControlButtons';
import { arrayCancelStatuses } from '../settings/arrayCancelStatuses';
import { CustomTrSC } from '../../../../FocusTable/styles';
import { ArrowClockwise28Filled, Dismiss28Filled } from '@fluentui/react-icons';
import { Title } from './Title';

export const BodyMonitoring = memo(() => {
    const [tableHeight, setTableHeight] = useState<number>(0);
    const [tableDataListTemp, setTableDataListTemp] = useState<ITableListTasksItem[]>([]);
    const [selectedCells, setSelectedCells] = useState<TableRange | null>(null);
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const { t } = useTranslation();
    const [statuses, setStatuses] = useState<IStatuses>({
        error: {
            number: 0,
            name: 'Error',
            count: 0,
        },
        pending: {
            number: 0,
            name: 'Pending',
            count: 0,
        },
        success: {
            number: 0,
            name: 'Success',
            count: 0,
        },
        noReceipts: {
            number: 0,
            name: 'No receipts',
            count: 0,
        },
    });
    const {
        selectedTypesConnector,
        selectedListRowTable,
        listSettingsTableMonitoring,
        tableListTasks,
        listTaskStatusesFilter,
        selectedTasks,
        taskDataByTaskId,
    } = getTasksStore();

    const { mutate: mutateCanceler, isPending: isPendingCanceler } = usePostTaskCanceler('statusReloadAndCanceler');
    const { mutate: mutateReload, isPending: isPendingCreater } = usePostTaskCreate('statusReloadAndCanceler');

    const tableColumns = useTableColumn({
        listSettings: listSettingsTableMonitoring,
        columnsOptions,
    });


    useEffect(() => {
        window.onresize = onSizeWindow;
        onResizeAfterEffect();

    }, []);

    useEffect(() => {
        let temp: ITableListTasksItem[] = filteringByConnectorTypes<ITableListTasksItem>({
            keys: ['connectorType'],
            list: selectedTypesConnector,
            filterList: tableListTasks,
            typeCompare: 'strict',
        });

        if (selectedTypesConnector.length === 0) {
            temp = tableListTasks;
        }

        temp = filteringListByTaskStatuses<ITableListTasksItem>({
            list: temp,
            listStatuses: listTaskStatusesFilter,
        });
        updateTasksStore({
            selectedTasks: [],
        });
        setSelectedCells(null);
        setTableDataListTemp(temp);
    }, [selectedTypesConnector, tableListTasks, listTaskStatusesFilter]);

    useEffect(() => {
        if (tableDataListTemp.length > 0) {
            const filteredError = tableDataListTemp.filter((item) => (item.statusCode ? item.statusCode < -4 : false));
            const filteredPending = tableDataListTemp.filter((item) =>
                item.statusCode ? item.statusCode === -4 : false,
            );
            const filteredSuccess = tableDataListTemp.filter((item) =>
                item.statusCode ? item.statusCode === -2 || item.statusCode > 0 : false,
            );
            const filteredWaiting = tableDataListTemp.filter((item) =>
                item.statusCode !== undefined ? item.statusCode === 0 : false,
            );

            const error = getPercentage(filteredError.length, tableDataListTemp.length);
            const pending = getPercentage(filteredPending.length, tableDataListTemp.length);
            const success = getPercentage(filteredSuccess.length, tableDataListTemp.length);
            const waiting = getPercentage(filteredWaiting.length, tableDataListTemp.length);
            setStatuses({
                error: {
                    number: error,
                    name: 'Error',
                    count: filteredError.length,
                },
                pending: {
                    number: pending,
                    name: 'Pending',
                    count: filteredPending.length,
                },
                success: {
                    number: success,
                    name: 'Success',
                    count: filteredSuccess.length,
                },
                noReceipts: {
                    number: waiting,
                    name: 'No receipts',
                    count: filteredWaiting.length,
                },
            });
        }
    }, [tableDataListTemp]);


    const getHeaderHeight = (): number => {
        return document.getElementById('HeaderMonitoring')
            ? document.getElementById('HeaderMonitoring')!.getBoundingClientRect().height
            : 0;
    };

    const onSizeWindow = (e: any) => {
        setTableHeight(e.target.innerHeight - (KFACTORHEIGHTTABLE_MONITORING + getHeaderHeight()));
    };

    const onResizeAfterEffect = () => {
        setTableHeight(window.innerHeight - (KFACTORHEIGHTTABLE_MONITORING + getHeaderHeight()));
    };

    const onSelectionChange = (selection: TableRange | null, selectedRows: ITableListTasksItem[]) => {
        updateTasksStore({
            selectedTasks: selectedRows,
        });
        setSelectedCells(selection);
    };


    const onCancelTask = (id: number) => {
        if (taskDataByTaskId) {
            const obj: IParamsCancelTask = {
                date_from: taskDataByTaskId[id].dateFrom!,
                date_to: taskDataByTaskId[id].dateFrom!,
                project_location_ids: [taskDataByTaskId[id].PLId!],
                tenant_ids: [taskDataByTaskId[id].tenantId!],
                connector_ids: [taskDataByTaskId[id].connectorId!],
                connector_types: [taskDataByTaskId[id].connectorType!],
            };
            mutateCanceler(obj);
        }
    };

    const onReloadTask = (id: number) => {
        if (taskDataByTaskId) {
            const obj: IBodyTaskCreator = {
                date_from: taskDataByTaskId[id].dateFrom!,
                date_to: taskDataByTaskId[id].dateFrom!,
                project_location_ids: [taskDataByTaskId[id].PLId!],
                tenant_ids: [taskDataByTaskId[id].tenantId!],
                connector_ids: [taskDataByTaskId[id].connectorId!],
                connector_types: [taskDataByTaskId[id].connectorType!],
            };
            mutateReload(obj);
        }
    };

    const createDataReq = () => {
        const chunkArray = getChunkArrays(selectedTasks, CHUNK_SIZE);
        let arrayBodies: IParamsCancelTask[] = [];
        chunkArray.forEach((item, i) => {
            arrayBodies.push(createBody(item) as IParamsCancelTask);
        });
        return arrayBodies;
    };

    const onCancel = () => {
        const arrayBodies: IParamsCancelTask[] = createDataReq();
        arrayBodies.forEach((item) => {
            mutateCanceler(item);
        });
    };

    const onRestart = () => {
        const arrayBodies: IBodyTaskCreator[] = createDataReq() as IBodyTaskCreator[];
        arrayBodies.forEach((item) => {
            mutateReload(item);
        });
    };

    const rowRenderer = useCallback(({ cells, children, ...props }: any) => {

        const onClickDismiss = () => {
            onCancelTask && onCancelTask(Number(cells[0].value));
        };

        const onClickReload = () => {
            onReloadTask && onReloadTask(Number(cells[0].value));
        };

        const getBGColor = () => {
            return null;
        };


        let isShowCancelButton: boolean = false;

        if (taskDataByTaskId) {
            const found = arrayCancelStatuses?.find(
                (item) => item.statusCode === taskDataByTaskId[cells[0].value]?.statusCode,
            );
            if (found) {
                isShowCancelButton = true;
            }
        }

        return (
            <CustomTrSC $bgOpacity={'1'} $bgColor={getBGColor()}>
                <CustomTdSC className="action-cell">
                    <DivActionCellSC>
                        {isShowCancelButton && (
                            <Tooltip content={t('Stop')} relationship="label">
                                <Button
                                    appearance="subtle"
                                    onClick={onClickDismiss}
                                    icon={<Dismiss28Filled color={'red'} />}
                                />
                            </Tooltip>
                        )}
                        <Tooltip content={t('Reload')} relationship="label">
                            <Button appearance="subtle" onClick={onClickReload} icon={<ArrowClockwise28Filled />} />
                        </Tooltip>
                    </DivActionCellSC>
                </CustomTdSC>
                {children}
            </CustomTrSC>
        );
    }, [
        JSON.stringify(taskDataByTaskId),
        JSON.stringify(arrayCancelStatuses)

    ]);

    const TableComponent = useMemo(() => {
        return (
            <FocusTable
                columnsOptions={tableColumns}
                maxHeight={tableHeight}
                selectedCells={selectedCells}
                // @ts-ignore
                onSelectionChange={onSelectionChange}
                // @ts-ignore
                value={tableDataListTemp}
                selectedListRow={selectedListRowTable}
                isShowActions={true}
                isPositionCell={true}
                rowRenderer={rowRenderer}
            />
        )
    }, [tableColumns, tableHeight, tableDataListTemp, tableDataListTemp])



    return (
        <BodyMonitoringTasksRowSC>
            <DivWrapperChartAndTitleSC>
                <Title s={t('Total tasks')}
                       iTableListTasksItems={tableDataListTemp}
                       s1={t('Date')}

                       s2={t('Created')}  />
                <DivWrapperChartsContentSC>
                    <ChartBox statuses={statuses} />
                    <div></div>
                    <ControlButtons
                        disabledButton={selectedTasks.length === 0}
                        onCancel={onCancel}
                        onRestart={onRestart}
                        selectedTasks={selectedTasks}
                        isPendingCancel={isPendingCanceler}
                        isPendingCreate={isPendingCreater}
                    />
                </DivWrapperChartsContentSC>
            </DivWrapperChartAndTitleSC>

            {TableComponent}
        </BodyMonitoringTasksRowSC>
    );
});
