import {
    IConnector,
    ITableListTasksItem,
    Locales,
    TConnectorTypes,
    TLocations,
    TTableListTasks,
    TTasks,
} from 'src/types';

import { useStore } from './useStore';
import { IResponseTenant, ITenant, TConnector, TResConnector } from '../types/tasks';
import { TConnectorReq } from '../types/connectors';
import { IConnectorItemListForTable, TListSettings } from '../components/Chapters/Tasks/interfaces';

const STORE_KEY = `TASKS_STORE_KEY`;

export const initialState = {
    selectedLocations: [] as TLocations,
    tenants: [] as ITenant[],
    resTenants: null as IResponseTenant | null,
    connectorTypes: [] as TConnectorTypes,
    connectors: [] as TConnector[],
    resConnectors: null as TResConnector | null,
    connectorDataByConnectorId: null as { [key: number]: TConnectorReq } | null,
    tenantDataByTenantId: null as { [key: number]: ITenant } | null,
    tableDataList: [] as IConnectorItemListForTable[],
    tableDataListCTG: [] as IConnectorItemListForTable[],
    selectedTypesConnector: [] as TConnectorTypes,
    tenantsFilterValue: '' as string,
    selectedConnector: [] as IConnectorItemListForTable[],
    statusSelectedAll: false as boolean,
    selectedListRowTable: [] as number[],
    selectedDateFrom: null as string | null,
    selectedDateTo: null as string | null,
    connectorsListIntersectionDate: [] as number[],
    listSettingsTable: [] as TListSettings,
    listSettingsTableMonitoring: [] as TListSettings,
    listTaskStatusesFilter: [] as TListSettings,
    tasks: [] as TTasks,
    tableListTasks: [] as TTableListTasks,
    tableListTasksCTG: [] as TTableListTasks,
    selectedCreatedFrom: null as string | null,
    selectedCreatedTo: null as string | null,
    selectedMonitoringDateFrom: null as string | null,
    selectedDateMonitoringTo: null as string | null,
    selectedTasks: [] as ITableListTasksItem[],
    taskDataByTaskId: null as { [key: number]: ITableListTasksItem } | null,
};

export type StoreType = typeof initialState;

export function useTaskStore(externalState?: StoreType) {
    const {
        store: tasksStore,
        getStore: getTasksStore,
        updateStore: updateTasksStore,
    } = useStore<StoreType>([STORE_KEY], externalState || { ...initialState });

    return { tasksStore, getTasksStore, updateTasksStore };
}
