import { IParamsFilteringListByTaskStatuses } from '../interfaces';
import filteringTableList from '../../../../tools/filteringTableList';
import { filteringTableDataMoreZero } from './filteringTableDataMoreZero';

const filteringListByTaskStatuses = <T>(params: IParamsFilteringListByTaskStatuses<T>): T[] => {
    const { listStatuses, list } = params;

    const tempValuesFilter = listStatuses
        .filter((item) => item.checked)
        .map((item) => {
            return item.status;
        });

    let temp: T[] = [];

    if (tempValuesFilter.length > 0) {
        tempValuesFilter.forEach((item) => {
            if (item === '>0') {
                temp = [
                    ...temp,
                    ...filteringTableDataMoreZero<T>({
                        keys: ['statusCode'],
                        list: list,
                        valueFilter: String(item),
                    }),
                ];
            } else {
                temp = [
                    ...temp,
                    ...filteringTableList<T>({
                        keys: ['statusCode'],
                        list: list,
                        valueFilter: String(item),
                        typeCompare: 'strict',
                    }),
                ];
            }
        });
    } else {
        temp = list;
    }

    return temp;
};

export default filteringListByTaskStatuses;
