import { ITableListTasksItem } from '../../../../../types';
import { Title3 } from '@fluentui/react-components';
import { SpanTitle3SC } from '../../styles';
import React from 'react';
import { useTaskStore } from '../../../../../store/useTaskStore';

export const Title = (props: {
    s: string,
    iTableListTasksItems: ITableListTasksItem[],
    s1: string,
    s2: string,
}) => {
    const { getTasksStore } = useTaskStore();
    const {
        selectedDateMonitoringTo,
        selectedMonitoringDateFrom,
        selectedCreatedFrom,
        selectedCreatedTo,
    } = getTasksStore();

    return (
        <Title3>{`${props.s}:`} <SpanTitle3SC>{props.iTableListTasksItems.length}</SpanTitle3SC> {`(${
            selectedMonitoringDateFrom
                ? `${props.s1}: ${selectedMonitoringDateFrom} - ${selectedDateMonitoringTo}`
                : selectedCreatedFrom
                    ? `${props.s2}: ${selectedCreatedFrom} - ${selectedCreatedTo}`
                    : ''
        })`}</Title3>
    );
};