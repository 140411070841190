import { ContainerSC } from '../styles';
import { HeaderMonitoring } from './components/HeaderMonitoring';
import { useGetAllLocations } from '../../../../api/useGetAllLocations';
import { useGetConnectorTypes } from '../../../../api/useGetConnectorTypes';
import { BodyMonitoring } from './components/BodyMonitoring';
import { memo } from 'react';

export const Monitoring = memo(() => {
    useGetAllLocations();
    useGetConnectorTypes();
    return (
        <ContainerSC>
            <HeaderMonitoring />
            <BodyMonitoring />
        </ContainerSC>
    );
});
