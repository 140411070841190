import React from 'react';


import { TableFilter, MenuContextArgs, MenuConfig, Identity } from '../interfaces';
import { xlsxExport } from '../xlsxExport';

import { SelectionRanger } from './SelectionRanger';
import { Search } from '../../Search/Search';


import { emptyArray, emptyObject, toString } from 'src/tools';

import {
    CellEvent,
    TableMenuArgs,
    TableContext,
    // TableMenuItem,
} from '../interfaces';
import { TFunction } from 'i18next';

const INPUT_MENU_ITEM_STYLES = { padding: 0 };

export type MenuItemRenderer<
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
> = (args: TableMenuArgs<I, R>) => React.ReactNode;



const findRowAndCol = (element: HTMLElement | null): { row: number; col: number } => {
    while (element) {
        const row = element.getAttribute('data-row');
        const col = element.getAttribute('data-col');
        if (row !== null && col !== null) {
            return { row: parseInt(row, 10), col: parseInt(col, 10) };
        }
        element = element.parentElement;
    }
    return { row: -1, col: -1 }; // Return default values if not found
};

const prepareCellEvent = <I extends string, R extends Identity<I>>(
    contextRef: React.RefObject<TableContext<I, R>>,
    contextMenuEvent: MouseEvent,
): CellEvent<I, R> => {

    if (!contextRef?.current) {
        throw new Error('Table context is not available');
    }

    const tableContext = contextRef.current;
    const targetElement = contextMenuEvent.target as HTMLElement;
    const { row, col } = findRowAndCol(targetElement);

    if (row === -1 || col === -1) {
        throw new Error('Row or column not found');
    }

    const rowData = tableContext.data[row];
    const column = tableContext.visibleColumns[col];
    const value = rowData ? rowData[column.id as keyof R] : null;

    return {
        row,
        col,
        column,
        value,
    };
};

const createComparableInputFilter = (comparer: (lhv: unknown, rhv: unknown) => boolean) => {
    function inputRenderer<
        I extends string = string, // id type
        R extends Identity<I> = Identity<I>,
    >({ menuEvent, contextRef, menuItemId }: TableMenuArgs<I, R>): React.ReactNode {

        const cellEvent = prepareCellEvent(contextRef, menuEvent)
        const currentFilter = contextRef?.current?.filters?.find?.(
            (filter) => filter.columnId === cellEvent?.column.id && filter.filterId === menuItemId,
        );
        const filterValue = currentFilter?.filterValue?.toString() || '';

        const changeValue = (newValue: string) => {
            const context = contextRef?.current;
            context && context.changeFilter((prevFilters: TableFilter[]) => {
                const withoutCurrentFilter = prevFilters.filter(
                    (filter) => filter.columnId !== cellEvent?.column.id || filter.filterId !== menuItemId,
                );

                if (!newValue?.length) {
                    return withoutCurrentFilter;
                }

                return [
                    ...withoutCurrentFilter,
                    {
                        columnId: cellEvent?.column.id,
                        filterId: menuItemId,
                        filterValue: newValue,
                        filterPredicate: (dataValue) => comparer(dataValue?.toString(), newValue),
                    },
                ];
            });
        };

        const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            const context = contextRef?.current;
            if (event.key === 'Escape') {
                context && context.closeMenu();
            }

            if (event.key !== 'Enter') {
                return;
            }
            const currentFilter = context && context.filters.find(
                (filter) => filter.columnId === cellEvent?.column.id && filter.filterId === menuItemId,
            );
            const filterValue = currentFilter?.filterValue?.toString() || '';

            const prevFilters = context?.filters || emptyArray;

            const withoutCurrentFilter = prevFilters.filter(
                (filter) => filter.columnId !== cellEvent?.column.id || filter.filterId !== menuItemId,
            );
            const newFilters = [
                ...withoutCurrentFilter,
                {
                    columnId: cellEvent?.column.id,
                    filterId: menuItemId,
                    filterValue,
                    filterPredicate: (dataValue: unknown) => comparer(dataValue, filterValue),
                },
            ];

            context && context.changeFilter(newFilters);
            context && context.closeMenu();
        };
        return (
            <Search onKeyDown={handleKeyDown} value={filterValue} handleChange={changeValue} />
        );
    }

    inputRenderer.isUniqueMenuItem = true;

    return inputRenderer;
};

const createItemRendererWithComparableFilter =
    (comparer: (lhv: unknown, rhv: unknown) => boolean) =>
        <
            I extends string = string, // id type
            R extends Identity<I> = Identity<I>,
        >(args: TableMenuArgs<I, R>) => {
            function itemRenderer<
                I extends string = string, // id type
                R extends Identity<I> = Identity<I>,
            >({ menuEvent, contextRef, menuItemId, label }: TableMenuArgs<I, R>): React.ReactNode {

                const cellEvent = prepareCellEvent(contextRef, menuEvent)

                const filterValue = cellEvent?.value?.toString();

                const handleClick = () => {
                    const context = contextRef?.current;
                    context && context.changeFilter((prevFilters: TableFilter[]) => {
                        const withoutCurrentFilter = prevFilters.filter(
                            (filter) => filter.columnId !== cellEvent?.column.id || filter.filterId !== menuItemId,
                        );
                        return [
                            ...withoutCurrentFilter,
                            {
                                columnId: cellEvent?.column.id,
                                filterId: menuItemId,
                                filterValue,
                                filterPredicate: (dataValue) => comparer(dataValue?.toString(), filterValue),
                            },
                        ];
                    });
                    context && context.closeMenu();
                };

                return (
                    <span onClick={handleClick}>
                    {label || menuItemId} "{toString(cellEvent.value)}"
                </span>
                );
            }

            return itemRenderer(args);
        };


export const getMenuConfig = <
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
>(t: TFunction) => {
    const menuItems: MenuConfig<I, R, CellEvent<I, R>, TableContext<I, R>> = [
        {
            id: 'filters',
            label: t('Filters'),
            groupItems: [
                {
                    id: 'include',
                    label: t('Include'),
                    subItems: [
                        {
                            id: 'includeInput',
                            label: t('Include input'),
                            itemStyle: INPUT_MENU_ITEM_STYLES,
                            renderer: createComparableInputFilter(
                                (lhv, rhv) =>
                                    toString(lhv).toLowerCase().includes?.(toString(rhv).toLowerCase()) || false,
                            ),
                        },

                    ],
                },
                {
                    id: 'includeValue',
                    label: t('Include value'),
                    renderer: createItemRendererWithComparableFilter(
                        (lhv, rhv) => toString(lhv) === toString(rhv),
                    ),
                },
                {
                    id: 'exclude',
                    label: t('Exclude'),
                    subItems: [
                        {
                            id: 'excludeInput',
                            label: t('Exclude input'),
                            itemStyle: INPUT_MENU_ITEM_STYLES,
                            renderer: createComparableInputFilter(
                                (lhv, rhv) => !toString(lhv).toLowerCase().includes?.(toString(rhv).toLowerCase()),
                            ),
                        },
                    ],
                },
                {
                    id: 'excludeValue',
                    label: t('Exclude value'),
                    renderer: createItemRendererWithComparableFilter(
                        (lhv, rhv) => !toString(lhv).includes?.(toString(rhv)),
                    ),
                },
                {
                    id: 'removeAllFilters',
                    label: t('Remove all filters'),
                    onItemClick: ({ contextRef }) => contextRef?.current?.changeFilter?.(emptyArray as TableFilter[]),
                },
            ],
        },
        null,
        {
            id: 'operations',
            label: t('Operations'),

            groupItems: [
                {
                    id: 'selection',
                    label: t('Selection'),
                    subItems: [
                        {
                            id: 'expandColumnsSelection',
                            label: t('Expand columns selection'),
                            onItemClick: ({ contextRef }) => {
                                const {
                                    changeSelection,
                                    data,
                                    visibleColumns,
                                    selection,
                                } = contextRef?.current || emptyObject as TableContext<I, R>;

                                if (!selection || !data?.length || !visibleColumns.length) {
                                    return;
                                }

                                const tableRange = {
                                    start: { i: 0, j: selection.start.j },
                                    end: { i: data.length - 1, j: selection.end.j },
                                };

                                changeSelection?.(tableRange);
                            },
                        },
                        {
                            id: 'selectAll',
                            label: t('Select all'),
                            onItemClick: ({ contextRef }) => {
                                const {
                                    changeSelection,
                                    data,
                                    visibleColumns,
                                } = contextRef?.current || emptyObject as TableContext<I, R>;

                                if (!data?.length || !visibleColumns.length) {
                                    return;
                                }

                                const tableRange = {
                                    start: { i: 0, j: 0 },
                                    end: { i: data.length - 1, j: visibleColumns.length - 1 },
                                };

                                changeSelection?.(tableRange);
                            },
                        },
                    ],
                },
                {
                    id: 'xlsxExport',
                    label: t('Save XLSX'),
                    subItems: [
                        {
                            id: 'exportSelectedCells',
                            label: t('Selected cells'),
                            checkVisibility: ({ contextRef }) => {
                                const ranger = new SelectionRanger(contextRef?.current?.selection);
                                return ranger.checkIsSelected();
                            },
                            onItemClick: ({ contextRef }) => {
                                const context = contextRef?.current || emptyObject as TableContext<I, R>;
                                const {
                                    componentId: tableId,
                                    data,
                                    visibleColumns,
                                    selection,
                                } = context;

                                if (!selection || !data?.length || !visibleColumns.length) {
                                    return;
                                }

                                const ranger = new SelectionRanger(selection);

                                const selectedColumns = visibleColumns.filter((_, colIndex) => ranger.hasCol(colIndex));
                                const selectedDataRows = data.filter((_, rowIndex) => ranger.hasRow(rowIndex));


                                xlsxExport({
                                    columns: selectedColumns,
                                    data: selectedDataRows,
                                    tableId,
                                    bookName: context.nameForExports,
                                    // @ts-ignore
                                    context,
                                });
                            },
                        },
                        {
                            id: 'allTable',
                            label: t('All the table'),

                            onItemClick: ({ contextRef }) => {
                                const context = contextRef?.current || emptyObject as TableContext<I, R>;
                                const {
                                    data,
                                    visibleColumns,
                                    componentId: tableId,
                                } = context;

                                if (!data?.length || !visibleColumns.length) {
                                    return;
                                }

                                xlsxExport({
                                    columns: visibleColumns,
                                    data,
                                    tableId,
                                    bookName: context.nameForExports,
                                    // @ts-ignore
                                    context,
                                });
                            },
                        },
                    ],
                },
            ],
        },
    ];

    return menuItems;
};

