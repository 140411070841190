import styled from 'styled-components';
import { css } from 'styled-components';
import ReactDataSheet from 'react-datasheet';
import { projectTheme } from '../../theme';
import { MenuGroupHeader, MenuItem, PopoverSurface } from '@fluentui/react-components';

export const StyledTable = styled(ReactDataSheet)`
    border: 1px solid var(--color-secondary-darker);

    .ht_clone_top {
        z-index: 10;
    }
    th {
        border: 1px solid var(--color-secondary-dark);
        padding: 0.35rem;
    }
    .ht__highlight {
        background-color: ${(p) => projectTheme.colorPaletteDarkRedBackground2 + '!important'};
    }
`;

const BaseCell = styled.div`
    color: var(--dsg-header-text-color);
    transition: color var(--dsg-transition-duration);
    box-shadow: none;
`;

export const CellWithSorting = styled(BaseCell)`
    position: relative;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid currentColor;
        opacity: 0.5;
    }

    &.sorted-asc::after {
        transform: translateY(-50%) rotate(180deg);
    }

    &.sorted-desc::after {
        border-bottom: none;
        border-top: 4px solid currentColor;
    }

    &.sorting-off {
        cursor: default;
        &:after {
            display: none;
        }
    }
`;

export const StyledSpan = styled.span<{ row: number; col: number }>``;

export const StyledCell = styled.td<{
    $align?: string;
    minWidth?: number;
    hideBorder?: boolean;
    borderTop?: string;
    padding?: string;
    $bgColor?: string;
}>`
    text-align: ${(p) => p.$align || (document.dir === 'rtl' ? 'right' : 'left')} !important;
    padding: ${(p) => p.padding || 'var(--space-xxxs)'} !important;
    /* vertical-align: middle !important; */
    border: 1px solid var(--color-secondary-dark) !important;
    max-width: 200px;
    background-color: ${({ $bgColor }) => $bgColor} !important;
    //span {
    //    height: 100%;
    //    display: flex !important;
    //}
`;

export const StyledHeaderCell = styled.th<{ padding?: string }>`
    vertical-align: middle !important;
    max-width: 50px;
    span {
        font-size: 14px;
        color: var(--color-contrast-higher);
        font-weight: 700;
    }
`;

// https://stackoverflow.com/questions/23989463/how-to-set-tbody-height-with-overflow-scroll
// http://jsbin.com/vujoyubu/1/edit
// https://css-tricks.com/making-tables-with-sticky-header-and-footers-got-a-bit-easier/
// https://jsfiddle.net/framj00/2eN3U/
export const StyledTableWrapper = styled.div<{ maxHeight?: number }>`
    border: 1px solid var(--color-secondary-darker);
    overflow-x: auto;
    //////////////////////////////////////////
    // table tbody {
    //     display: block;
    //     $ {(p) =>
    //     overflow-y: scroll;
    // }

    // table {
    //     min-width: 100%;
    //     // display: block;
    //     width: auto;
    // }

    // thead,
    // tbody tr {
    //     display: table;
    //     width: 100%;
    //     table-layout: fixed;
    // }

    // thead {
    //     background: var(--color-secondary-light);
    // }

    //////////////////////////////////////////
    overflow-y: auto;
    table {
        min-width: 100%;
    }

    ${(p) => css`
        ${p.maxHeight ? `max-height: ${p.maxHeight}px !important` : ''};
    `}

    thead {
        background: var(--color-secondary-light);

        position: sticky;
        top: -1px;
        z-index: 10;
    }
`;

export const CustomTrSC = styled.tr<{
    $bgColor?: string | null;
    $bgOpacity?: string | null;
}>`
    background: ${({ $bgColor }) => $bgColor && $bgColor};
    opacity: ${({ $bgOpacity }) => $bgOpacity && $bgOpacity};
`;

export const DivWrapperTextSC = styled.div`
    width: 100%;
    -webkit-line-clamp: 2; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden;
    //grid-template-columns: 100%;
    //grid-template-rows: 100%;
`;

export const StyledMenuGroupHeader = styled(MenuGroupHeader)`
    
`;