import React, { useCallback, useDeferredValue, useEffect, useRef, memo } from 'react';

import { emptyArray } from 'src/tools';

import { ContextMenu } from './components/ContextMenu';
import { defaultValueRenderer, defaultDataRenderer } from './defaultRenderers';
import { usePrepareTableProps } from './usePrepareTableProps';
import { FocusTableProps, Identity, WrapperStyleProps } from './interfaces';
import {  DivWrapperTextSC, StyledCell, StyledHeaderCell, StyledTable, StyledTableWrapper } from './styles';

import { useTranslation } from 'react-i18next';
import {  DivActionHeaderCellSC } from '../Chapters/Tasks/styles';

import { Button, Tooltip } from '@fluentui/react-components';
import { colorsByNameStatus } from '../Chapters/Tasks/Monitoring/settings/colorsByNameStatus';
import { positionByNameColumn } from '../Chapters/Tasks/Monitoring/settings/positionByNameColumn';

const idStatusByNameStatus: { [key: string]: string } = {
    Success: 'success',
    'No receipts': 'noReceipts',
    Pending: 'pending',
    'Saved to bucket': 'savedToBucket',
};

export const FocusTable = memo(<I extends string = string, R extends Identity<I> = Identity<I>>({
    maxHeight,
    listEnableRow = [],
    isDisabledRows = false,
    isShowCheckBox = false,
    isShowActions = false,
    isPositionCell = false,
                                                                                               rowRenderer,
                                                                                               wrapperStyle,
    ...propsG
}: FocusTableProps<I, R> & { wrapperStyle?: WrapperStyleProps }) => {
    const {
        columnsOptions = emptyArray,
        headerCellRenderer,
        tableContextRef,
        contextMenuEvent,
        onMouseDown,
        isSelectedAll = false,
        selectAllRow,
        ...restProps
    } = usePrepareTableProps<I, R>({ ...propsG });
    // const isRtl = document.documentElement.getAttribute('dir') === 'rtl';
    // useEffect(() => {
    //     // console.log('isRtl', isRtl);
    //     // tableContextRef.current.isRtl = isRtl;
    // }, [isRtl]);
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const wrapperElement = wrapperRef.current;

    useEffect(() => {
        const handleKeyDownListener = (event: globalThis.KeyboardEvent) => {
            event.preventDefault(); // Prevent default behavior for key press
            event.stopPropagation(); // Stop the event from propagating further
        };

        const element = wrapperElement; // closure - to unsubscribe
        if (element === null || element === undefined) {
            return;
        }

        element!.addEventListener('keydown', handleKeyDownListener);
        return () => element!.removeEventListener('keydown', handleKeyDownListener);
    }, [wrapperElement]);

    const cellRenderer = useCallback(({ cell, row, col, ...props }: any) => {
        const style = columnsOptions[col]?.style;
        const column = columnsOptions[col];
        const handleMouseDown = (event: MouseEvent) => {
            // event to show menu on selected range
            onMouseDown(event);

            if (event.button === 2) {
                // to show menu on selected range
                event.preventDefault();
                return;
            }
            // const selection = {
            //     start: { i: row, j: col },
            //     end: { i: row, j: col },
            // };
            // tableContextRef.current.selection = selection;
            // handlers should to work for other mouse buttons
            props.onMouseDown(event);
        };

        const getBGColor = () => {
            let isStatus = false;
            if (columnsOptions[col]) {
                isStatus = columnsOptions[col].id === 'status';
            }

            if (isStatus) {
                // @ts-ignore
                if (propsG.value[row]?.rowBGColor) {
                    if (idStatusByNameStatus[cell.value]) {
                        return colorsByNameStatus[idStatusByNameStatus[cell.value]];
                    }
                    return colorsByNameStatus.error;
                }
                return null;
            }
            return null;
        };

        if (columnsOptions[col]?.id === 'error') {
            return (
                <StyledCell
                    $bgColor={getBGColor()}
                    $align={isPositionCell ? positionByNameColumn[columnsOptions[col].id] : undefined}
                    data-row={row}
                    data-col={col}
                    {...props}
                    onMouseDown={handleMouseDown}
                    {...style}
                >
                    <Tooltip content={cell?.value || null} relationship="label">
                        <DivWrapperTextSC>{cell?.value || null}</DivWrapperTextSC>
                    </Tooltip>
                </StyledCell>
            );
        }

        const renderer = column?.cellRenderer || column?.valueFormatter;
        const value = cell?.value !== undefined ? cell.value : null;
        return (
            <StyledCell
                $bgColor={getBGColor()}
                data-row={row}
                data-col={col}
                $align={isPositionCell ? positionByNameColumn[columnsOptions[col]?.id] || 'right' : undefined}
                {...props}
                onMouseDown={handleMouseDown}
                {...style}
            >
                {renderer ? renderer(value, tableContextRef.current) : value}
            </StyledCell>
        );
    }, [columnsOptions, onMouseDown, tableContextRef, propsG.value]);

    const sheetRenderer = useCallback(({ ...props }: any) => {
        const onChecked = () => {
            selectAllRow && selectAllRow(!isSelectedAll, propsG.value);
        };


        return (
            <table className={props.className}>
                <thead>
                    <tr>
                        {isShowCheckBox && (
                            <StyledHeaderCell className="action-cell">
                                <input type="checkbox" checked={isSelectedAll} onChange={onChecked} />
                            </StyledHeaderCell>
                        )}
                        {isShowActions && (
                            <StyledHeaderCell className="action-cell">
                                <DivActionHeaderCellSC>{t('Task control')}</DivActionHeaderCellSC>
                            </StyledHeaderCell>
                        )}

                        {columnsOptions.map(headerCellRenderer)}
                    </tr>
                </thead>
                <tbody>{props.children}</tbody>
            </table>
        );
    } , [columnsOptions, headerCellRenderer, propsG.value, isSelectedAll]);


    const defferedData = useDeferredValue(restProps.data);

    return (
        <StyledTableWrapper maxHeight={maxHeight}>
            {/*// @ts-ignore*/}
            <StyledTable
                {...restProps}
                valueRenderer={defaultValueRenderer}
                cellRenderer={cellRenderer}
                dataRenderer={defaultDataRenderer}
                sheetRenderer={sheetRenderer}
                data={defferedData}
                rowRenderer={rowRenderer}
            />

            <ContextMenu contextRef={tableContextRef} contextMenuEvent={contextMenuEvent} />
        </StyledTableWrapper>
    );
});
